var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Work-Study Status Tracker")]),_c('v-spacer'),(_vm.isAdmin)?_c('v-autocomplete',{staticClass:"ml-2",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.supervisorList,"label":"Supervisor","item-text":"name","item-value":"pidm","outlined":"","hide-details":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var ref_item = ref.item;
var email = ref_item.email;
var name = ref_item.name;
var count = ref_item.count;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name || '**No Supervisor**'))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(email))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(count)+" student"+_vm._s(count > 1 ? 's' : ''))])],1)]}}],null,false,3737631677),model:{value:(_vm.supervisor),callback:function ($$v) {_vm.supervisor=$$v},expression:"supervisor"}}):_vm._e(),(_vm.view === 'timesheet')?_c('v-select',{staticClass:"ml-2",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.payPeriods,"label":"Pay Period","return-object":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.payPeriod),callback:function ($$v) {_vm.payPeriod=$$v},expression:"payPeriod"}}):_vm._e(),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"outlined":""}},on),[_c('span',[_vm._v(_vm._s(_vm.viewOptions.find(function (ref) {
	var value = ref.value;

	return value === _vm.view;
}).text || 'Work Hour Progress'))]),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.viewOptions),function(ref){
var value = ref.value;
var text = ref.text;
return _c('v-list-item',{key:value,on:{"click":function($event){return _vm.changeView(value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1)],1),_c('v-card-text',[_c('p',{staticStyle:{"margin-bottom":"0"}},[_vm._v(_vm._s(_vm.view === 'work' ? 'Tracking and Hours Remaining ' : 'Timesheet columns (status and hours) ')+" are loaded from Banner and updated hourly.")])]),_c('v-data-table',{attrs:{"items":_vm.students,"headers":_vm.headers,"loading":_vm.loading},on:{"dblclick:row":function (e, ref) {
	var item = ref.item;

	return _vm.openTimeclock(item);
}},scopedSlots:_vm._u([{key:"item.W4",fn:function(ref){
var item = ref.item;
return [('W4' in item && item.W4)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("fal fa-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Completed: "+_vm._s(_vm.stringFormatDateToday(item.W4)))])]):('W4' in item)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")]):_vm._e()]}},{key:"item.DD",fn:function(ref){
var item = ref.item;
return [('DD' in item && item.DD)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("fal fa-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Completed: "+_vm._s(_vm.stringFormatDateToday(item.DD)))])]):('DD' in item)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")]):_vm._e()]}},{key:"item.WB",fn:function(ref){
var item = ref.item;
return [('WB' in item && item.WB)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("fal fa-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Completed: "+_vm._s(_vm.stringFormatDateToday(item.WB)))])]):('WB' in item)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")]):_vm._e()]}},{key:"item.SC",fn:function(ref){
var item = ref.item;
return [('SC' in item && item.SC)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("fal fa-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Completed: "+_vm._s(_vm.stringFormatDateToday(item.SC)))])]):('SC' in item)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")]):_vm._e()]}},{key:"item.SV",fn:function(ref){
var item = ref.item;
return [('SV' in item && item.SV)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("fal fa-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Completed: "+_vm._s(_vm.stringFormatDateToday(item.SV)))])]):('SV' in item)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("fal fa-times-circle")]):_vm._e()]}},{key:"item.rtw",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.rtw ? 'success' : 'error'}},[_vm._v("fas fa-"+_vm._s(item.rtw ? 'check' : 'times')+"-circle")])]}},{key:"item.tracking",fn:function(ref){
var item = ref.item;
return [(item.track)?_c('time-gauge',{attrs:{"percentComplete":item.track.compPerc,"targetPercent":item.track.targetPerc}}):_vm._e()]}},{key:"item.latest",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s('clock' in item && 'latest' in item.clock ? _vm.stringFormatDateToday(item.clock.latest) : ''))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("fal fa-ellipsis-v")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.view === 'work' || _vm.view === 'timesheet')?_c('v-list-item',{on:{"click":function($event){return _vm.openTimeclock(item)}}},[(_vm.view === 'work')?_c('v-list-item-title',[_vm._v("View Recent Timeclock Entries")]):_vm._e(),(_vm.view === 'timesheet')?_c('v-list-item-title',[_vm._v("View Timeclock Entries For This Period")]):_vm._e()],1):_vm._e(),_c('v-list-item',{attrs:{"to":'/student/schedule/' + item.bannerId}},[_c('v-list-item-title',[_vm._v("View Student's Schedule")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }